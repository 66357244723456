const dummyJobs = [
  {
    title: "Post_vacant",
    company: "Company_name",
    location: "City",
    duration: "Posted time",
    companylogo: "https://cdn1.iconfinder.com/data/icons/google-s-logo/150/Google_Icons-09-512.png",
    tags: ["condition", "condition", "condition"],
  },
  {
    title: "Post_vacant",
    company: "Company_name",
    location: "City",
    duration: "Posted time",
    companylogo: "https://cdn1.iconfinder.com/data/icons/google-s-logo/150/Google_Icons-09-512.png",
    tags: ["condition", "condition", "condition"],
  },
  {
    title: "Post_vacant",
    company: "Company_name",
    location: "City",
    duration: "Posted time",
    companylogo: "https://cdn1.iconfinder.com/data/icons/google-s-logo/150/Google_Icons-09-512.png",
    tags: ["condition", "condition", "condition"],
  },
  {
    title: "Post_vacant",
    company: "Company_name",
    location: "City",
    duration: "Posted time",
    companylogo: "https://cdn1.iconfinder.com/data/icons/google-s-logo/150/Google_Icons-09-512.png",
    tags: ["condition", "condition", "condition"],
  },
  {
    title: "Post_vacant",
    company: "Company_name",
    location: "City",
    duration: "Posted time",
    companylogo: "https://cdn1.iconfinder.com/data/icons/google-s-logo/150/Google_Icons-09-512.png",
    tags: ["condition", "condition", "condition"],
  },
  {
    title: "Post_vacant",
    company: "Company_name",
    location: "City",
    duration: "Posted time",
    companylogo: "https://cdn1.iconfinder.com/data/icons/google-s-logo/150/Google_Icons-09-512.png",
    tags: ["condition", "condition", "condition"],
  },
  {
    title: "Post_vacant",
    company: "Company_name",
    location: "City",
    duration: "Posted time",
    companylogo: "https://cdn1.iconfinder.com/data/icons/google-s-logo/150/Google_Icons-09-512.png",
    tags: ["condition", "condition", "condition"],
  },
  {
    title: "Post_vacant",
    company: "Company_name",
    location: "City",
    duration: "Posted time",
    companylogo: "https://cdn1.iconfinder.com/data/icons/google-s-logo/150/Google_Icons-09-512.png",
    tags: ["condition", "condition", "condition"],
  },
  {
    title: "Post_vacant",
    company: "Company_name",
    location: "City",
    duration: "Posted time",
    companylogo: "https://cdn1.iconfinder.com/data/icons/google-s-logo/150/Google_Icons-09-512.png",
    tags: ["condition", "condition", "condition"],
  },
  {
    title: "Post_vacant",
    company: "Company_name",
    location: "City",
    duration: "Posted time",
    companylogo: "https://cdn1.iconfinder.com/data/icons/google-s-logo/150/Google_Icons-09-512.png",
    tags: ["condition", "condition", "condition"],
  },
  {
    title: "Post_vacant",
    company: "Company_name",
    location: "City",
    duration: "Posted time",
    companylogo: "https://cdn1.iconfinder.com/data/icons/google-s-logo/150/Google_Icons-09-512.png",
    tags: ["condition", "condition", "condition"],
  },
  {
    title: "Post_vacant",
    company: "Company_name",
    location: "City",
    duration: "Posted time",
    companylogo: "https://cdn1.iconfinder.com/data/icons/google-s-logo/150/Google_Icons-09-512.png",
    tags: ["condition", "condition", "condition"],
  },
  {
    title: "Post_vacant",
    company: "Company_name",
    location: "City",
    duration: "Posted time",
    companylogo: "https://cdn1.iconfinder.com/data/icons/google-s-logo/150/Google_Icons-09-512.png",
    tags: ["condition", "condition", "condition"],
  },
  {
    title: "Post_vacant",
    company: "Company_name",
    location: "City",
    duration: "Posted time",
    companylogo: "https://cdn1.iconfinder.com/data/icons/google-s-logo/150/Google_Icons-09-512.png",
    tags: ["condition", "condition", "condition"],
  },
  {
    title: "Post_vacant",
    company: "Company_name",
    location: "City",
    duration: "Posted time",
    companylogo: "https://cdn1.iconfinder.com/data/icons/google-s-logo/150/Google_Icons-09-512.png",
    tags: ["condition", "condition", "condition"],
  },
  {
    title: "Post_vacant",
    company: "Company_name",
    location: "City",
    duration: "Posted time",
    companylogo: "https://cdn1.iconfinder.com/data/icons/google-s-logo/150/Google_Icons-09-512.png",
    tags: ["condition", "condition", "condition"],
  },
  {
    title: "Post_vacant",
    company: "Company_name",
    location: "City",
    duration: "Posted time",
    companylogo: "https://cdn1.iconfinder.com/data/icons/google-s-logo/150/Google_Icons-09-512.png",
    tags: ["condition", "condition", "condition"],
  },
  {
    title: "Post_vacant",
    company: "Company_name",
    location: "City",
    duration: "Posted time",
    companylogo: "https://cdn1.iconfinder.com/data/icons/google-s-logo/150/Google_Icons-09-512.png",
    tags: ["condition", "condition", "condition"],
  },
  {
    title: "Post_vacant",
    company: "Company_name",
    location: "City",
    duration: "Posted time",
    companylogo: "https://cdn1.iconfinder.com/data/icons/google-s-logo/150/Google_Icons-09-512.png",
    tags: ["condition", "condition", "condition"],
  },
  {
    title: "Post_vacant",
    company: "Company_name",
    location: "City",
    duration: "Posted time",
    companylogo: "https://cdn1.iconfinder.com/data/icons/google-s-logo/150/Google_Icons-09-512.png",
    tags: ["condition", "condition", "condition"],
  },
  {
    title: "Post_vacant",
    company: "Company_name",
    location: "City",
    duration: "Posted time",
    companylogo: "https://cdn1.iconfinder.com/data/icons/google-s-logo/150/Google_Icons-09-512.png",
    tags: ["condition", "condition", "condition"],
  }
];

export default dummyJobs;
